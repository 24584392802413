import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"
import { css } from "@emotion/core"

const AirtableLink = ({tableId, viewId, recordId}) => {
  // If no baseId is provided, use the one in ENV variable
  return (
    <a
      href={`https://airtable.com/${tableId}/${viewId}/${recordId}?blocks=hide`}
      target="_blank"
      rel="noreferrer noopener"
      onClick={(e)=>{
        e.stopPropagation()
      }}
      css={css`
        font-size: 12px;
        text-decoration: none;
        &:hover {
          font-weight: bold;
        }
      `}
    >
       Airtable <FaExternalLinkAlt />
    </a>
   )
}

export default AirtableLink