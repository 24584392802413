// sort the sessions, merge the event engagements on the event key,, calculate registrants and attendees
const CleanAndMapSessions = (sessions) => {
  const result = {}
  for (var i in sessions) {
    const session = sessions[i]
    if(session.Type === 'Event' && session.Event ) {
      // consolidate the company lookup fields into the company field
      // (due to nonsense in Airtable, event engagements have to lookup the company id via the member)
      if(session['Company Lookup via Member']) {
        session.Company = session['Company Lookup via Member']
      }
    }
    if(session.Company) {
      if(result[session.Company[0]]) {
        result[session.Company[0]].push(session)
      } else {
        result[session.Company[0]] = [session]
      }
    }
  }
  return result
}

export default CleanAndMapSessions